@import "../../../../assets/styles/includes.scss";

.video-page {
  flex-basis: 100%;
  background: #000000;
  overflow-y: hidden;
  .big-video {
    height: 850px;
  }
  .video-row {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    overflow-x: scroll;
    gap: 20px;
    padding: 20px;
    .video-card {
      &.active {
        border: 4px solid $yellow;
      }
      border-radius: 20px;
      height: 100%;
      position: relative;
      h2 {
        font-size: 24px;
        color: $wit;
        max-width: 175px;
        top: -5px;
        position: absolute;
        left: 20px;
      }
      .video {
        border-radius: 20px;
        cursor: pointer;
        > div video {
          opacity: 0.15;
          border-radius: 20px;
        }
      }
    }
  }
}

  @include respond-below(lg){
    .Video-page{
      background-color: $zwart;

        .mobile-title{
          color: $wit;
        }
        .video-page {
          overflow-y: scroll;

    .video-row {
      flex-direction: column;
      align-items: center;

      .video-mobile-card{
        border-radius: 10px;
        background-color: #333333;
        width: auto;

        h2{
            padding: 20px;
            margin: 0;
            color: $yellow;
            font-size: em(24px)
          }
        }
      }
    }
  }
}
.Video-page{
  display: block!important;
}
