@import "../../../../assets/styles/includes.scss";
.Parkeren {
  background-image: url("../../../../assets/icons/parkeren.svg");
  @extend %icon-style;
}
.Historie-cultuur {
  background-image: url("../../../../assets/icons/cultuur.svg");
  @extend %icon-style;
}
.Landschap-beheer {
  background-image: url("../../../../assets/icons/landschap.svg");
  @extend %icon-style;
}
.Amusement {
  background-image: url("../../../../assets/icons/fietsen-blue.svg");
  color: yellow;

  @extend %icon-style;
}
.Eten-Drinken {
  background-image: url("../../../../assets/icons/etendrinken.svg");
  @extend %icon-style;
}
.Overnachten {
  background-image: url("../../../../assets/icons/overnachten.svg");
  @extend %icon-style;
}
