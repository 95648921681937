@import "../../../assets/styles/includes.scss";
.sidebar {
  background-color: $aubergine;
  min-width: 330px;
  ul {
    margin: 0;
    @include respond-below(lg) {
      margin: 30px 20px;
    }
  }
}
.qr-sidebar {
  background-color: $wit;
  min-width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .back {
    display: flex;
    gap: 0.5em;
    color: black;
    &:hover {
      cursor: pointer;
    }
    margin-left: -2em;
    span {
      font-weight: 600;
    }
  }
}
.route-link {
  background-color: $aubergine;
  border-radius: 10px;
  a {
    color: #ffffff;
    font-size: em(18px);
    display: flex;
    padding: 20px;
    margin: 12px 0px;
    pointer-events: unset;
  }
}

.qr-code {
  padding: 0 1em;
  align-self: center;
  justify-content: flex-end;
  p {
    max-width: 210px;
    font-size: 0.5em;
  }
}

.Wandelroutes {
  background-image: url("../../../assets/icons/wandelen.svg");
  @extend %icon-style;
}
.Fietsroutes {
  background-image: url("../../../assets/icons/fietsen.svg");
  @extend %icon-style;
}
.Rondvaarten {
  background-image: url("../../../assets/icons/varen.svg");
  @extend %icon-style;
}
.Paardrijden {
  background-image: url("../../../assets/icons/paardrijden.svg");
  @extend %icon-style;
}
