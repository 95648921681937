@import "../../../../../assets/styles/includes.scss";
.cardslist {
  width: 100%;
  overflow-y: scroll;
  @include respond-above(delf) {
    width: 1000px;
  }

  .cards {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    flex: 1;
    gap: 35px;
    margin-left: 1em;
    margin-top: 1em;
    @include respond-below(lg) {
      margin: 0;
      padding: 20px 20px;
    }
    .card {
      background-color: white;
      color: black;
      border-radius: 20px;
      cursor: pointer;
      width: 435px;
      @include respond-below(lg) {
        border-radius: 10px;
        min-height: 325px;
        width: 370px;
      }

      .card-body {
        padding: 10px 20px;

        .card-title {
          margin: 0.1em 0;
        }
        .card-subtitle {
          margin-top: 0;
          margin-bottom: 0.8em;
          color: $jade-green;
          @include respond-below(lg) {
            max-width: 295px;
          }
        }
        img {
          border-radius: 10px;
          object-fit: cover;
          height: 234px;
          @include respond-below(lg) {
            max-height: 211px;
          }
        }
      }
    }
  }
}
.end {
  text-align: center;
  color: $inactive-typo;
  padding: 0.5em 0 2em 0;
}
.loading-screen {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}
