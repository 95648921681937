@import "../../assets/styles/includes.scss";
.sidebar li.inactive {
  background-color: $inactive;
  color: $inactive-typo;
}

.buttons {
  @include respond-below(lg) {
    padding: 0;
    ul {
      margin: 0 20px;
      width: 100%;
    }
  }

  li {
    @include respond-below(lg) {
      background-position: 90% 50%;
      width: 100%;
      border-radius: 10px;
      height: 72px;
    }
    &:hover {
      cursor: pointer;
    }
    width: 250px;
    color: black;
    height: 128px;
    border-radius: 20px;
    font-weight: 700;
    display: flex;
    list-style: none;
    margin-bottom: 1em;

    .button-title {
      padding: 20px;
      font-size: em(24px);

      @include respond-below(lg) {
        padding: 15px 20px;
      }
    }
  }
}
.Routes {
  background-color: $oww-red;
}
.Locaties {
  background-color: $lightBlue;
}
.Agenda {
  background-color: $lightGreen;
}
.Videos {
  background-color: $yellow;
}
.Midden-Delfland-in-Beeld {
  background-color: $jade-green;
}

.menuSub {
  li {
    background-color: white;
    color: black;
  }
  li.inactive {
    background-color: $inactive;
    color: $inactive-typo;
  }
}

.shake {
  animation: shake 0.2s;
}
