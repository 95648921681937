@import "../../../../assets/styles/includes.scss";

.welkom {
  display: flex;
  flex-direction: column;
  padding: 0 0.5em;
  background-color: $aubergine;
  @include respond-below(lg) {
    padding: 0 20px;
  }
  h1 {
    margin-top: 3.5em;
    margin-bottom: 0;
    color: $wit;
    letter-spacing: 0.05em;
    line-height: 1.185;
    max-width: 500px;
    @include respond-below(lg) {
      margin-top: 1em;
      font-size: em(36px);
    }
  }

  p {
    color: $wit;
    @include respond-below(lg) {
      font-size: em(18px);
      margin: 10 0 0 0;
    }
  }

  .animation {
    align-self: center;
    margin-left: 9em;
  }
}
