// colours
$oww-red: #ff534a;
$aubergine: #460c37;
$yellow: #eee887;
$inactive: rgba(255, 255, 255, 0.25);
$inactive-typo: rgba(255, 255, 255, 0.5);
$jade-green: #4dac7d;
$landmarks: #f9a141;
$lightGreen: #a0d0a3;
$lightBlue: #88d2da;
$routes: #ec4544;
$verblijf: #f27a9c;
$zwart: #000000;
$wit: #ffffff;

// variables

$fontsize_base: 36px;

// extends
%icon-style {
  background-repeat: no-repeat;
  background-position: 10% 80%;
@respond-below(lg){
    width: 37px;
  }
}

// functions

@function oww-font-size($n, $fb: $fontsize_base, $unit: 1em) {
  @return ($n / $fb) * $unit;
}
@function em($n, $fb: $fontsize_base) {
  @return oww-font-size($n, $fb);
}

// FUNCTIONS AND REUSABLES
//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
  xs: 576px,
  sm: 780px,
  md: 992px,
  lg: 1224px,
  delf: 1681px,
  xl: 1700px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
