@import "../../../../../assets/styles/includes.scss";
.page {
  width: 100%;
  @include respond-below(lg){
   > div{
      padding: 0 20px;
   }
   >div :nth-child(3){
     padding: 0 !important;
   }
   .swiper{
     overflow: unset;
   }
  }
  .page-title {
    padding-top: 30px;
    margin-bottom: 0;

    @include respond-below(lg){
      font-size: em(24px);
    }
  }
  .page-subtitle {
    margin-top: 20px;
    margin-bottom: 60px;
    font-size: em(24px);
    text-transform: uppercase;
    @include respond-below(lg){
      font-size: em(18px);
      margin-bottom: 20px;
    }
  }

  .page-text {
    width: 910px;
    @include respond-below(lg){
      width: 100%;
      font-size: em(18px);
    }
  }

  .img-slider, .video-slider{
    @include respond-below(lg){

      width: auto!important;
      height: 187px!important;
    }
  }
}
video::-webkit-media-controls-fullscreen-button, video::-webkit-media-controls-toggle-closed-captions-button
{
  display: none;
}
.img-slider, .field--name-field-media-video-file video, .image-style-paragraaf{
  border-radius: 10px;
}

a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

video {
  &::-webkit-media-controls-play-button {
    background-size: 120%;
  }
}

.field--type-image, .field--name-field-media-video-file{
  img , video{
  width: 100% !important;
  height: 100% !important;
}
}


