@import "../../../../assets/styles/includes.scss";

.logo-wrapper {
  display: flex;
  min-height: 140px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0.6em;
  .logo {
    margin-left: -0.5em;
  }
  .startscherm {
    display: flex;
    color: $wit;
    gap: 0.5em;
    &:hover {
      cursor: pointer;
    }
  }

  .back-button-text {
    font-size: em(24px);
  }
}

.logo-wrapper-mobile {
  height: 69px;
  position: relative;
  .logo {
    top: 20px;
    left: 20px;
    position: absolute;
    height: 72px;
  }
}

.mobile-back-wrapper {
  display: flex;
  flex-direction: row;
  margin: 1em 0 0 40px;
  gap: 20px;
  width: 100%;
  .mobile-back {
    background: $wit;
    width: 48px;
    border-radius: 10px;
    height: 48px;
    position: relative;
    &:hover {
      cursor: pointer;
    }
    img {
      position: absolute;
      left: 25%;
      top: 25%;
      width: 20px;
      height: 24px;
    }
  }
  .mobile-title {
    font-size: em(24px);
    align-self: center;
    font-weight: bolder;
    @include respond-below(lg) {
      max-width: 250px;
    }
  }
  .logo-wrapper {
    min-height: 10px;
  }
}
