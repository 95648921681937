@import "../../assets/styles/includes.scss";

body {
  background-color: $zwart;
  color: $zwart;
  font-family: Interstate Reg, sans-serif;
  font-size: $fontsize_base;
  line-height: 1.5;
}

h1 {
  font-size: em(54px);
}

h5 {
  font-size: em(24px);
}
h6 {
  font-size: em(18px);
}

.page {
  padding: 0 5.7em;
  @include respond-below(lg){
    overflow-x: hidden;
  }
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @include respond-below(lg){
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }
}

.list {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.detail {
  padding: em(60px) em(205px);
}

.kiosk {
  display: flex;
  @include respond-below(lg){
    flex-direction: column;
    overflow-x: hidden;
    height: 100%;
    background-color: $aubergine;

  }
}

::-webkit-scrollbar {
  display: none;
}
@include respond-below(lg){
.menuSub{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  ul {
    margin-top: 20px;
    padding:0 20px;
  }
 }
}
