@import "../../../../assets/styles/includes.scss";

.Midden-Delfland-in-Beeld {
  .infopage {
    h1,
    h2 {
      padding: 0 3.75em;
    }

    .page-text {
      p {
        padding: 0 5.7em;
        min-width: 910px;
      }
    }

    .colofon {
      background-color: white;
      padding: 0.5em 5.7em;
      h2 {
        margin: 0.5em 0 0.2em 0;
        padding-left: 0;
      }
      table {
        width: 100%;
        margin-bottom: -3.5em;
        td {
          font-size: em(24px);
          width: 50%;
          padding: 20px 0;
        }
      }
      p {
        font-size: em(24px);
      }
    }

    .logos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .logo-info {
        padding: 10px 0;
        flex: 1 1 50%;
        .logo-vlafilms {
          width: 200px;
          height: 140px;
        }
        .logo-hofvandelfland {
          width: 100px;
        }
        img {
          max-width: 300px;
        }
      }
    }
    .europa {
      display: flex;
      column-gap: 20px;
      align-items: center;

      p {
        max-width: 590px;
      }
      .logo-europa {
        max-width: 163px;
        margin-top: 15px;
      }
    }
  }

  @include respond-below(lg) {
    .infopage {
      h1 {
        padding: 0 20px;
        max-width: 267px;
        font-size: em(24px);
      }
      .page-text {
        p {
          padding: 0 20px;
          min-width: unset;
          font-size: em(18px);
        }
      }
      .page-text {
        p {
          font-size: em(18px);
          padding: 0 20px;
        }
      }

      .colofon {
        padding: 10px 20px;
        h2 {
          font-size: em(24px);
        }
        table {
          td {
            font-size: em(18px);
          }
        }
        p {
          font-size: em(18px);
        }
      }

      .logos {
        .logo-info {
          img {
            max-width: 150px;
            max-height: 70px;
            object-fit: contain;
          }
        }
      }
      .europa {
        font-size: em(18px);

        p {
          max-width: 240px;
        }

        .logo-europa {
          max-width: 75px;
        }
      }
    }
  }

  @include respond-below(lg) {
    .mobile-back-wrapper {
      margin: 1em 0 0 20px;
      padding-left: 20px;
    }
  }
}
