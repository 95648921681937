body {
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
